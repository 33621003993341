<template>
    <section>
        <modal ref="CalificacionesPedido" titulo="Calificacion del predido" icon="review" no-aceptar>
            <div class="row mx-0 justify-center ">
                <div class="col-5 d-middle-center text-general f-600">
                    {{ pedido.calificacion }} <i class="icon-star f-20 orange-star" />
                </div>
            </div>
            <hr class="my-3" />
            <div v-for="(calificacion, c) in calificaciones" :key="c" class="row mx-0 px-5 py-2 border-bottom">
                <div class="col-4">
                    <p class="d-middle text-general">
                        {{ calificacion.nombre }}
                    </p>
                </div>
                <div class="col-6">
                    <el-rate
                    v-model="calificacion.calificacion"
                    disabled
                    score-template="{value}"
                    />
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12 text-center py-2">
                    <p class="text-muted2 f-15">
                        {{ _.defaultTo(pedido.calificacion_comentario,'Sin comentario') }}
                    </p>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import Calificaciones from "../../../services/calificaciones";
import { mapGetters } from 'vuex'

export default {
    data(){
        return{
            icono: ['icon-star'],
            calificaciones: [],
            id_pedido: null,
            pedido: {
                id: null,
                calificacion: null,
                calificacion_comentario: null
            }
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        id_tienda(){
            let id_tienda = null
            if (this.$route.params.id_tienda){
                id_tienda = this.$route.params.id_tienda
            } else {
                id_tienda = 0
            }

            return id_tienda
        },
        caso(){
            if (this.$route.name == 'admin.tiendas.produccion.calificaciones'){
                return 1
            } else {
                return 2
            }
        }
    },
    methods: {
        toggle(id){
            this.id_pedido = id
            this.$refs.CalificacionesPedido.toggle();
            this.get_calificaciones()
        },
        async get_calificaciones(){
            try {
                let params = {
                    caso: this.caso,
                    id_tienda: this.id_tienda,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Calificaciones.get_calificaciones(this.id_pedido, params)
                this.calificaciones = data.data.calificaciones
                this.pedido = data.data.pedido
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>
<style lang="css" scoped>
.orange-star{
    color: #FF9D32;
}
</style>
